import React from "react"

import SmoothScrollbar from "../components/scrollbar"
import SEO from "../components/seo"

import "../styles/about.scss"

const AboutPage = () => {
  return (
    <>
      <SEO title="About" />
      <SmoothScrollbar>
        <main style={{ minHeight: "200vh" }}></main>
      </SmoothScrollbar>
    </>
  )
}

export default AboutPage
